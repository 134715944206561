import { type TimelineCountEvent, type TimelineEvent } from "@evercam/ui"
import {
  type CameraExid,
  type ProjectExid,
  type TimelineDateInterval,
  type TimelineProviderRequestParams,
} from "@evercam/shared/types"
import { TimelineDataProvider } from "./timelineDataProvider"
import { EvercamLabsApi } from "@evercam/shared/api/evercamLabsApi"

export class TimelineLuminanceProvider extends TimelineDataProvider {
  readonly projectExid: ProjectExid
  readonly cameraExid: CameraExid
  readonly token: string

  constructor(params: {
    timezone: string
    projectExid: ProjectExid
    cameraExid: CameraExid
    token: string
  }) {
    super(params.timezone)
    this.projectExid = params.projectExid
    this.cameraExid = params.cameraExid
    this.token = params.token
  }

  async fetchEvents({
    fromDate,
    toDate,
  }: TimelineDateInterval): Promise<Array<TimelineEvent>> {
    const interval = new Date(toDate).getTime() - new Date(fromDate).getTime()
    const maxPadding = Math.floor(interval / 10)
    const padding = Math.max(3600000, maxPadding)

    const paddedFromDate = new Date(new Date(fromDate).getTime() - padding)
    const paddedToDate = new Date(new Date(toDate).getTime() + padding)

    const items = await EvercamLabsApi.luminance.getReadings({
      cameraExid: this.cameraExid,
      fromDate: this.formatTimestamp(paddedFromDate.toISOString()),
      toDate: this.formatTimestamp(paddedToDate.toISOString()),
    })

    return items.map(({ timestamp, luminanceDelta, ...rest }) => {
      return {
        timestamp: this.formatTimestamp(timestamp.replace(".000Z", "")),
        text: luminanceDelta > 0 ? "▲" : "▼",
        luminanceDelta,
        ...rest,
      }
    })
  }

  async fetchCounts({
    fromDate,
    toDate,
  }: TimelineProviderRequestParams): Promise<Array<TimelineCountEvent>> {
    return this.fetchEvents({
      fromDate,
      toDate,
    }) as unknown as TimelineCountEvent[]
  }
}
